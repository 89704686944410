import { REWARD_TYPES } from 'constant/constants';
import _, { filter } from 'lodash';
import * as dollarOffAnimationData from 'lottie/voucher-dollar-off.json';
import * as freeItemAnimationData from 'lottie/voucher-free-item.json';
import * as percentageOffAnimationData from 'lottie/voucher-percentage-off.json';
import moment, { Moment } from 'moment';
import Lottie from 'react-lottie';
import { Voucher } from './types';

const numberWithCommas = (x: string | undefined) => {
  try {
    if (!x) throw new Error('Input is undefined');
    return x.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } catch (err) {
    return x;
  }
};

export const getPriceWithCurrency = (
  price: string | number,
  { decimalPlaces, currency }: { decimalPlaces?: number; currency?: string } = {},
) => {
  const prefix = '';
  const postfix = '';

  if ((!price && price != 0) || price === 'NaN') return null;

  const actualPrice = typeof price === 'string' ? Number(price.trim().replace(',', '')) : price;

  const result = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency ?? 'USD', // USD will make it show just $
    maximumFractionDigits: decimalPlaces ?? 0,
    minimumFractionDigits: decimalPlaces ?? 0,
  }).format(actualPrice);

  return prefix + numberWithCommas(result) + postfix;
};

export const formatVoucher = ({ rewardType, voucherData, platform }: Voucher, brandName: string) => {
  const { subTittle, voucherImage, currency, highlightColor, titleHighlight, minOrderValue } = voucherData;
  let { title } = voucherData;

  if (title && titleHighlight) {
    return {
      titleHighlight,
      highlightColor,
      title,
      subtitle: subTittle,
      voucherImageUrl: _.get(voucherImage, '0.mediaUrl'),
    };
  }

  const data = (minOrderValue ? [`Min order ${getPriceWithCurrency(minOrderValue, { currency })}`] : []).concat(
    voucherData.withPurchaseOf ? [`With purchase of ${voucherData.withPurchaseOf}`] : [],
  );

  const platformText = platform
    ? 'platform' in platform && platform.platform
      ? ` ${_.capitalize(platform.platform)}`
      : ''
    : '';
  title = `on${platformText} order at ${brandName}`;

  switch (rewardType) {
    case REWARD_TYPES.DISCOUNT_AMOUNT:
      return {
        emphasis: `${getPriceWithCurrency(voucherData.amountOff, { currency })} off`,
        title,
        subtitle: data.join(', '),
      };
    case REWARD_TYPES.PERCENTAGE_OFF: {
      if (voucherData.cappedAt) {
        data.push(`${data.length > 0 ? 'c' : 'C'}apped at ${getPriceWithCurrency(voucherData.cappedAt, { currency })}`);
      }

      return {
        emphasis: `${voucherData.percentageOff}% off`,
        title,
        subtitle: data.join(', '),
      };
    }
    case REWARD_TYPES.FREE_ITEM: {
      return {
        emphasis: `Free ${voucherData.freeItem}`,
        title,
        subtitle: data.join(', '),
      };
    }
    default:
      return {
        emphasis: '',
        title: '',
        subtitle: '',
      };
  }
};

export const hasVoucherExpired = (expiryDateText: string | null) =>
  expiryDateText !== null && moment().format('YYYY-MM-DD') > expiryDateText;

export const getVoucherIcon = (rewardType: string) => {
  switch (rewardType) {
    case REWARD_TYPES.DISCOUNT_AMOUNT:
      return (
        <Lottie
          options={{
            animationData: dollarOffAnimationData,
            loop: true,
          }}
          speed={0.7}
          width={80}
          height={80}
        />
      );
    case REWARD_TYPES.PERCENTAGE_OFF:
      return (
        <Lottie
          options={{
            animationData: percentageOffAnimationData,
            loop: true,
          }}
          speed={0.7}
          width={80}
          height={80}
        />
      );
    case REWARD_TYPES.FREE_ITEM:
      return (
        <Lottie
          options={{
            animationData: freeItemAnimationData,
            loop: true,
          }}
          speed={0.7}
          width={80}
          height={80}
        />
      );
  }
};

export function format24TimeTo12(timeString: string) {
  const [hourString, minute] = timeString.split(':');
  const hour = +hourString % 24;
  return (hour % 12 || 12) + ':' + minute + (hour < 12 ? ' am' : ' pm');
}

export function findLatestDay(days: (Moment | string)[]) {
  const filteredDays = filter(days, (day) => day) as (Moment | string)[];

  if (filteredDays.length === 0) {
    return null;
  }

  let latestDay = moment(filteredDays[0]);

  for (let i = 1; i < filteredDays.length; i++) {
    const currentDay = moment(filteredDays[i]);

    if (currentDay.isAfter(latestDay)) {
      latestDay = currentDay;
    }
  }

  return latestDay;
}

export function findEarliestDay(days: (Moment | string)[]) {
  const filteredDays = filter(days, (day) => day) as (Moment | string)[];
  if (filteredDays.length === 0) {
    return null;
  }

  let earliestDay = moment(filteredDays[0]);

  for (let i = 1; i < filteredDays.length; i++) {
    const currentDay = moment(filteredDays[i]);

    if (currentDay.isBefore(earliestDay)) {
      earliestDay = currentDay;
    }
  }

  return earliestDay;
}
export function removeParamFromUrl(paramToRemove: string): void {
  try {
    const url = window.location.href;
    const urlParts = url.split('?');
    if (urlParts.length >= 2) {
      const baseUrl = urlParts[0];
      const queryParams = urlParts[1].split('&');
      const updatedParams = queryParams.filter((param) => {
        const [key] = param.split('=');
        return key !== paramToRemove;
      });
      const updatedQueryString = updatedParams.join('&');
      if (updatedQueryString !== '') {
        window.history.replaceState(null, '', `${baseUrl}?${updatedQueryString}`);
      } else {
        window.history.replaceState(null, '', baseUrl);
      }
    } else {
      console.warn('URL does not contain any query parameters.');
    }
  } catch (error: any) {
    console.error('An error occurred while removing parameter:', error?.message);
  }
}
